/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CitationGroupTitle_citationGroup = {
    readonly cgName: string;
    readonly " $refType": "CitationGroupTitle_citationGroup";
};
export type CitationGroupTitle_citationGroup$data = CitationGroupTitle_citationGroup;
export type CitationGroupTitle_citationGroup$key = {
    readonly " $data"?: CitationGroupTitle_citationGroup$data;
    readonly " $fragmentRefs": FragmentRefs<"CitationGroupTitle_citationGroup">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CitationGroupTitle_citationGroup",
  "selections": [
    {
      "alias": "cgName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "CitationGroup",
  "abstractKey": null
};
(node as any).hash = '2f3c6d840039fb1183ba8612c0f168cd';
export default node;
