/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Rank = "aberratio" | "class_" | "cohort" | "division" | "domain" | "family" | "form" | "genus" | "hyperfamily" | "informal" | "informal_species" | "infraclass" | "infrafamily" | "infrakingdom" | "infraorder" | "infraphylum" | "infrasubspecific" | "infratribe" | "kingdom" | "magnorder" | "morph" | "mutation" | "natio" | "order" | "other" | "other_family" | "other_species" | "other_subgeneric" | "parvorder" | "phylum" | "race" | "root" | "semiorder" | "species" | "species_group" | "subclass" | "subcohort" | "subfamily" | "subgenus" | "subkingdom" | "suborder" | "subphylum" | "subspecies" | "subtribe" | "subvariety" | "superclass" | "supercohort" | "superfamily" | "superkingdom" | "superorder" | "superphylum" | "synonym" | "synonym_family" | "synonym_genus" | "synonym_high" | "synonym_species" | "tribe" | "unranked" | "unranked_family" | "variety" | "%future added value";
export type ClassificationEntryBody_classificationEntry = {
    readonly ceName: string;
    readonly ceRank: Rank;
    readonly page: string | null;
    readonly article: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    };
    readonly mappedName: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly authority: string | null;
    readonly year: string | null;
    readonly citation: string | null;
    readonly ceTL: string | null;
    readonly parent: {
        readonly ceRank: Rank;
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly tags: ReadonlyArray<{
        readonly __typename: "CorrectedName";
        readonly text: string;
    } | {
        readonly __typename: "TextualRank";
        readonly text: string;
    } | {
        readonly __typename: "PageLink";
        readonly url: string;
        readonly page: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model" | "ClassificationEntryChildren_classificationEntry">;
    readonly " $refType": "ClassificationEntryBody_classificationEntry";
};
export type ClassificationEntryBody_classificationEntry$data = ClassificationEntryBody_classificationEntry;
export type ClassificationEntryBody_classificationEntry$key = {
    readonly " $data"?: ClassificationEntryBody_classificationEntry$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassificationEntryBody_classificationEntry">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": "ceRank",
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ModelLink_model"
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassificationEntryBody_classificationEntry",
  "selections": [
    {
      "alias": "ceName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "article",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Name",
      "kind": "LinkedField",
      "name": "mappedName",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "year",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "citation",
      "storageKey": null
    },
    {
      "alias": "ceTL",
      "args": null,
      "kind": "ScalarField",
      "name": "typeLocality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClassificationEntry",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "CorrectedName",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "TextualRank",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "PageLink",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClassificationEntryChildren_classificationEntry"
    }
  ],
  "type": "ClassificationEntry",
  "abstractKey": null
};
})();
(node as any).hash = 'ef7f574f7f20388dd0d1d3e499e9e41e';
export default node;
