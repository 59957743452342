/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GenderArticle = "art30_1_1" | "art30_1_2" | "art30_1_3" | "art30_1_4_2" | "art30_1_4_3" | "art30_1_4_4" | "art30_1_4_5" | "art30_2_1" | "art30_2_2" | "art30_2_3" | "art30_2_4" | "assumed" | "bad_transliteration" | "stem_expressly_set" | "unknown_obvious_stem" | "%future added value";
export type GrammaticalGender = "feminine" | "masculine" | "neuter" | "%future added value";
export type NameComplexBody_nameComplex = {
    readonly stem: string | null;
    readonly codeArticle: GenderArticle;
    readonly gender: GrammaticalGender;
    readonly stemRemove: string;
    readonly stemAdd: string;
    readonly comment: string | null;
    readonly " $fragmentRefs": FragmentRefs<"NameComplexNames_nameComplex">;
    readonly " $refType": "NameComplexBody_nameComplex";
};
export type NameComplexBody_nameComplex$data = NameComplexBody_nameComplex;
export type NameComplexBody_nameComplex$key = {
    readonly " $data"?: NameComplexBody_nameComplex$data;
    readonly " $fragmentRefs": FragmentRefs<"NameComplexBody_nameComplex">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameComplexBody_nameComplex",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "codeArticle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stemRemove",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stemAdd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameComplexNames_nameComplex"
    }
  ],
  "type": "NameComplex",
  "abstractKey": null
};
(node as any).hash = '6f8e2bf40488c83a7be52a84798446b3';
export default node;
