/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NamingConvention = "ancient" | "burmese" | "chinese" | "dutch" | "english" | "english_peer" | "french" | "general" | "german" | "hungarian" | "italian" | "japanese" | "korean" | "mongolian" | "organization" | "other" | "pinyin" | "portuguese" | "russian" | "spanish" | "turkish" | "ukrainian" | "unspecified" | "vietnamese" | "%future added value";
export type PersonTitle_person = {
    readonly familyName: string;
    readonly givenNames: string | null;
    readonly initials: string | null;
    readonly tussenvoegsel: string | null;
    readonly suffix: string | null;
    readonly namingConvention: NamingConvention;
    readonly " $refType": "PersonTitle_person";
};
export type PersonTitle_person$data = PersonTitle_person;
export type PersonTitle_person$key = {
    readonly " $data"?: PersonTitle_person$data;
    readonly " $fragmentRefs": FragmentRefs<"PersonTitle_person">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonTitle_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "givenNames",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initials",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tussenvoegsel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namingConvention",
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
(node as any).hash = 'da1fde53de104354943eba86ecd414ce';
export default node;
