/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PersonCollectedAll_person = {
    readonly oid: number;
    readonly " $fragmentRefs": FragmentRefs<"PersonCollectedAll_personInner">;
    readonly " $refType": "PersonCollectedAll_person";
};
export type PersonCollectedAll_person$data = PersonCollectedAll_person;
export type PersonCollectedAll_person$key = {
    readonly " $data"?: PersonCollectedAll_person$data;
    readonly " $fragmentRefs": FragmentRefs<"PersonCollectedAll_person">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCitationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCollectionDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showEtymologyDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showLocationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showNameDetail"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonCollectedAll_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "showCitationDetail",
          "variableName": "showCitationDetail"
        },
        {
          "kind": "Variable",
          "name": "showCollectionDetail",
          "variableName": "showCollectionDetail"
        },
        {
          "kind": "Variable",
          "name": "showEtymologyDetail",
          "variableName": "showEtymologyDetail"
        },
        {
          "kind": "Variable",
          "name": "showLocationDetail",
          "variableName": "showLocationDetail"
        },
        {
          "kind": "Variable",
          "name": "showNameDetail",
          "variableName": "showNameDetail"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PersonCollectedAll_personInner"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
(node as any).hash = '7c3c63f34651f0d2ca5bc331cf6ae42d';
export default node;
