/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Status = "composite" | "dubious" | "hybrid" | "nomen_dubium" | "redirect" | "removed" | "species_inquirenda" | "spurious" | "synonym" | "valid" | "%future added value";
export type NameSubtitle_name = {
    readonly status: Status;
    readonly taxon: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    };
    readonly " $refType": "NameSubtitle_name";
};
export type NameSubtitle_name$data = NameSubtitle_name;
export type NameSubtitle_name$key = {
    readonly " $data"?: NameSubtitle_name$data;
    readonly " $fragmentRefs": FragmentRefs<"NameSubtitle_name">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameSubtitle_name",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Taxon",
      "kind": "LinkedField",
      "name": "taxon",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ModelLink_model"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Name",
  "abstractKey": null
};
(node as any).hash = 'a18002509c5a41f9d6a7f06ca0ac53a8';
export default node;
