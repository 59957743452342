/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PageTitle_model = {
    readonly __typename: string;
    readonly callSign: string;
    readonly oid: number;
    readonly pageTitle: string;
    readonly " $fragmentRefs": FragmentRefs<"Title_model">;
    readonly " $refType": "PageTitle_model";
};
export type PageTitle_model$data = PageTitle_model;
export type PageTitle_model$key = {
    readonly " $data"?: PageTitle_model$data;
    readonly " $fragmentRefs": FragmentRefs<"PageTitle_model">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageTitle_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callSign",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageTitle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Title_model"
    }
  ],
  "type": "Model",
  "abstractKey": "__isModel"
};
(node as any).hash = '2954f56883d928e674ce57d75da25d13';
export default node;
