/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionTypeSpecimens_collectionInner = {
    readonly oid: number;
    readonly numTypeSpecimens: number;
    readonly typeSpecimens: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly oid: number;
            } | null;
        } | null>;
        readonly " $fragmentRefs": FragmentRefs<"NameList_connection">;
    } | null;
    readonly " $refType": "CollectionTypeSpecimens_collectionInner";
};
export type CollectionTypeSpecimens_collectionInner$data = CollectionTypeSpecimens_collectionInner;
export type CollectionTypeSpecimens_collectionInner$key = {
    readonly " $data"?: CollectionTypeSpecimens_collectionInner$data;
    readonly " $fragmentRefs": FragmentRefs<"CollectionTypeSpecimens_collectionInner">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "oid",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCitationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCollectionDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showEtymologyDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showLocationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showNameDetail"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "typeSpecimens"
        ]
      }
    ]
  },
  "name": "CollectionTypeSpecimens_collectionInner",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numTypeSpecimens",
      "storageKey": null
    },
    {
      "alias": "typeSpecimens",
      "args": null,
      "concreteType": "NameConnection",
      "kind": "LinkedField",
      "name": "__CollectionTypeSpecimens_typeSpecimens_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NameEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Name",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "showCitationDetail",
              "variableName": "showCitationDetail"
            },
            {
              "kind": "Variable",
              "name": "showCollectionDetail",
              "variableName": "showCollectionDetail"
            },
            {
              "kind": "Variable",
              "name": "showEtymologyDetail",
              "variableName": "showEtymologyDetail"
            },
            {
              "kind": "Variable",
              "name": "showLocationDetail",
              "variableName": "showLocationDetail"
            },
            {
              "kind": "Variable",
              "name": "showNameDetail",
              "variableName": "showNameDetail"
            }
          ],
          "kind": "FragmentSpread",
          "name": "NameList_connection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Collection",
  "abstractKey": null
};
})();
(node as any).hash = 'b9a5361e39ef67aa5787a780dc75bf57';
export default node;
