/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LocationBody_location = {
    readonly minPeriod: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly maxPeriod: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly stratigraphicUnit: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly locationRegion: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    };
    readonly tags: ReadonlyArray<{
        readonly __typename: "PBDB";
        readonly id: string;
    } | {
        readonly __typename: "NOW";
        readonly id: string;
    } | {
        readonly __typename: "ETMNA";
        readonly id: string;
    } | {
        readonly __typename: "General";
        readonly _Ignored: string | null;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $fragmentRefs": FragmentRefs<"LocationTypeLocalities_location">;
    readonly " $refType": "LocationBody_location";
};
export type LocationBody_location$data = LocationBody_location;
export type LocationBody_location$key = {
    readonly " $data"?: LocationBody_location$data;
    readonly " $fragmentRefs": FragmentRefs<"LocationBody_location">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationBody_location",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Period",
      "kind": "LinkedField",
      "name": "minPeriod",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Period",
      "kind": "LinkedField",
      "name": "maxPeriod",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StratigraphicUnit",
      "kind": "LinkedField",
      "name": "stratigraphicUnit",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "locationRegion",
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "PBDB",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "NOW",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "ETMNA",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "_Ignored",
              "storageKey": null
            }
          ],
          "type": "General",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LocationTypeLocalities_location"
    }
  ],
  "type": "Location",
  "abstractKey": null
};
})();
(node as any).hash = 'e8924eec28330739d78acbbb99358467';
export default node;
