/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NameComplexNames_nameComplex = {
    readonly oid: number;
    readonly " $fragmentRefs": FragmentRefs<"NameComplexNames_nameComplexInner">;
    readonly " $refType": "NameComplexNames_nameComplex";
};
export type NameComplexNames_nameComplex$data = NameComplexNames_nameComplex;
export type NameComplexNames_nameComplex$key = {
    readonly " $data"?: NameComplexNames_nameComplex$data;
    readonly " $fragmentRefs": FragmentRefs<"NameComplexNames_nameComplex">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCitationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCollectionDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showEtymologyDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showLocationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showNameDetail"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameComplexNames_nameComplex",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "showCitationDetail",
          "variableName": "showCitationDetail"
        },
        {
          "kind": "Variable",
          "name": "showCollectionDetail",
          "variableName": "showCollectionDetail"
        },
        {
          "kind": "Variable",
          "name": "showEtymologyDetail",
          "variableName": "showEtymologyDetail"
        },
        {
          "kind": "Variable",
          "name": "showLocationDetail",
          "variableName": "showLocationDetail"
        },
        {
          "kind": "Variable",
          "name": "showNameDetail",
          "variableName": "showNameDetail"
        }
      ],
      "kind": "FragmentSpread",
      "name": "NameComplexNames_nameComplexInner"
    }
  ],
  "type": "NameComplex",
  "abstractKey": null
};
(node as any).hash = '3bb7800517888f5b9ff359e88be984f9';
export default node;
