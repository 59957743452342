/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CitationGroupPatternTitle_citationGroupPattern = {
    readonly pattern: string;
    readonly " $refType": "CitationGroupPatternTitle_citationGroupPattern";
};
export type CitationGroupPatternTitle_citationGroupPattern$data = CitationGroupPatternTitle_citationGroupPattern;
export type CitationGroupPatternTitle_citationGroupPattern$key = {
    readonly " $data"?: CitationGroupPatternTitle_citationGroupPattern$data;
    readonly " $fragmentRefs": FragmentRefs<"CitationGroupPatternTitle_citationGroupPattern">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CitationGroupPatternTitle_citationGroupPattern",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pattern",
      "storageKey": null
    }
  ],
  "type": "CitationGroupPattern",
  "abstractKey": null
};
(node as any).hash = '61a21b8141b83f3006d157b166dee530';
export default node;
