/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Rank = "aberratio" | "class_" | "cohort" | "division" | "domain" | "family" | "form" | "genus" | "hyperfamily" | "informal" | "informal_species" | "infraclass" | "infrafamily" | "infrakingdom" | "infraorder" | "infraphylum" | "infrasubspecific" | "infratribe" | "kingdom" | "magnorder" | "morph" | "mutation" | "natio" | "order" | "other" | "other_family" | "other_species" | "other_subgeneric" | "parvorder" | "phylum" | "race" | "root" | "semiorder" | "species" | "species_group" | "subclass" | "subcohort" | "subfamily" | "subgenus" | "subkingdom" | "suborder" | "subphylum" | "subspecies" | "subtribe" | "subvariety" | "superclass" | "supercohort" | "superfamily" | "superkingdom" | "superorder" | "superphylum" | "synonym" | "synonym_family" | "synonym_genus" | "synonym_high" | "synonym_species" | "tribe" | "unranked" | "unranked_family" | "variety" | "%future added value";
export type ClassificationEntryTitle_classificationEntry = {
    readonly ceName: string;
    readonly ceRank: Rank;
    readonly ceParent: {
        readonly ceRank: Rank;
    } | null;
    readonly " $refType": "ClassificationEntryTitle_classificationEntry";
};
export type ClassificationEntryTitle_classificationEntry$data = ClassificationEntryTitle_classificationEntry;
export type ClassificationEntryTitle_classificationEntry$key = {
    readonly " $data"?: ClassificationEntryTitle_classificationEntry$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassificationEntryTitle_classificationEntry">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": "ceRank",
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassificationEntryTitle_classificationEntry",
  "selections": [
    {
      "alias": "ceName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": "ceParent",
      "args": null,
      "concreteType": "ClassificationEntry",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ClassificationEntry",
  "abstractKey": null
};
})();
(node as any).hash = '1341c180838fec530f9341237aea0d09';
export default node;
