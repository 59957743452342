/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Group = "family" | "genus" | "high" | "species" | "%future added value";
export type NameTitle_name = {
    readonly originalName: string | null;
    readonly rootName: string;
    readonly group: Group;
    readonly authorTags: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"TaxonomicAuthority_authorTags">;
    }>;
    readonly numericYear: number | null;
    readonly pageDescribed: string | null;
    readonly " $refType": "NameTitle_name";
};
export type NameTitle_name$data = NameTitle_name;
export type NameTitle_name$key = {
    readonly " $data"?: NameTitle_name$data;
    readonly " $fragmentRefs": FragmentRefs<"NameTitle_name">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameTitle_name",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rootName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "group",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "authorTags",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaxonomicAuthority_authorTags"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numericYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageDescribed",
      "storageKey": null
    }
  ],
  "type": "Name",
  "abstractKey": null
};
(node as any).hash = '430319ed3f63f5229c0f47e670a37097';
export default node;
