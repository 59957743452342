/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClassificationEntrySubtitle_classificationEntry = {
    readonly article: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    };
    readonly " $refType": "ClassificationEntrySubtitle_classificationEntry";
};
export type ClassificationEntrySubtitle_classificationEntry$data = ClassificationEntrySubtitle_classificationEntry;
export type ClassificationEntrySubtitle_classificationEntry$key = {
    readonly " $data"?: ClassificationEntrySubtitle_classificationEntry$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassificationEntrySubtitle_classificationEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassificationEntrySubtitle_classificationEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "article",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ModelLink_model"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClassificationEntry",
  "abstractKey": null
};
(node as any).hash = '150cf084b46e936eef15d16eb8ee101f';
export default node;
