/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RegionBody_region = {
    readonly " $fragmentRefs": FragmentRefs<"RegionChildren_region" | "RegionCitationGroups_region" | "RegionCollections_region" | "RegionPeriods_region" | "RegionLocations_region" | "RegionTypeLocalities_region">;
    readonly " $refType": "RegionBody_region";
};
export type RegionBody_region$data = RegionBody_region;
export type RegionBody_region$key = {
    readonly " $data"?: RegionBody_region$data;
    readonly " $fragmentRefs": FragmentRefs<"RegionBody_region">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegionBody_region",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionChildren_region"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionCitationGroups_region"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionCollections_region"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionPeriods_region"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionLocations_region"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionTypeLocalities_region"
    }
  ],
  "type": "Region",
  "abstractKey": null
};
(node as any).hash = 'b398d088153a1b552a86400828e38a34';
export default node;
