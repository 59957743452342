/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleCommentTitle_articleComment = {
    readonly article: {
        readonly " $fragmentRefs": FragmentRefs<"ArticleTitle_article">;
    };
    readonly " $refType": "ArticleCommentTitle_articleComment";
};
export type ArticleCommentTitle_articleComment$data = ArticleCommentTitle_articleComment;
export type ArticleCommentTitle_articleComment$key = {
    readonly " $data"?: ArticleCommentTitle_articleComment$data;
    readonly " $fragmentRefs": FragmentRefs<"ArticleCommentTitle_articleComment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleCommentTitle_articleComment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "article",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArticleTitle_article"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ArticleComment",
  "abstractKey": null
};
(node as any).hash = '50c6b8cfc4812cf58e6dae0f0d63e6ea';
export default node;
