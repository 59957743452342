/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SearchBox_modelCls = {
    readonly callSign: string;
    readonly name: string;
    readonly autocompletions: ReadonlyArray<string>;
    readonly " $refType": "SearchBox_modelCls";
};
export type SearchBox_modelCls$data = SearchBox_modelCls;
export type SearchBox_modelCls$key = {
    readonly " $data"?: SearchBox_modelCls$data;
    readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBox_modelCls",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callSign",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autocompletions",
      "storageKey": null
    }
  ],
  "type": "ModelCls",
  "abstractKey": null
};
(node as any).hash = 'e8da424ab2f4939e9fdf92fe285955a8';
export default node;
