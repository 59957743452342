/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PeriodRank = "_bed" | "_formation" | "_group" | "_member" | "_subgroup" | "_supergroup" | "age" | "biozone" | "eon" | "epoch" | "era" | "other_chronostratigraphy" | "period" | "subage" | "zonation" | "%future added value";
export type PeriodSystem = "_lithostratigraphy" | "aflma" | "alma" | "aulma" | "elma" | "gts" | "local_biostratigraphy" | "nalma" | "salma" | "%future added value";
export type PeriodSubtitle_period = {
    readonly rank: PeriodRank;
    readonly system: PeriodSystem;
    readonly " $refType": "PeriodSubtitle_period";
};
export type PeriodSubtitle_period$data = PeriodSubtitle_period;
export type PeriodSubtitle_period$key = {
    readonly " $data"?: PeriodSubtitle_period$data;
    readonly " $fragmentRefs": FragmentRefs<"PeriodSubtitle_period">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PeriodSubtitle_period",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "system",
      "storageKey": null
    }
  ],
  "type": "Period",
  "abstractKey": null
};
(node as any).hash = 'e77a91df166104b507a589a1656a3be6';
export default node;
