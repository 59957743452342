/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AgeClass = "bite_trace" | "burrow" | "coprolite" | "egg" | "extant" | "fossil" | "holocene" | "ichno" | "recently_extinct" | "redirect" | "removed" | "track" | "%future added value";
export type Rank = "aberratio" | "class_" | "cohort" | "division" | "domain" | "family" | "form" | "genus" | "hyperfamily" | "informal" | "informal_species" | "infraclass" | "infrafamily" | "infrakingdom" | "infraorder" | "infraphylum" | "infrasubspecific" | "infratribe" | "kingdom" | "magnorder" | "morph" | "mutation" | "natio" | "order" | "other" | "other_family" | "other_species" | "other_subgeneric" | "parvorder" | "phylum" | "race" | "root" | "semiorder" | "species" | "species_group" | "subclass" | "subcohort" | "subfamily" | "subgenus" | "subkingdom" | "suborder" | "subphylum" | "subspecies" | "subtribe" | "subvariety" | "superclass" | "supercohort" | "superfamily" | "superkingdom" | "superorder" | "superphylum" | "synonym" | "synonym_family" | "synonym_genus" | "synonym_high" | "synonym_species" | "tribe" | "unranked" | "unranked_family" | "variety" | "%future added value";
export type TaxonBody_taxon = {
    readonly taxonRank: Rank;
    readonly validName: string;
    readonly age: AgeClass;
    readonly parent: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly baseName: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    };
    readonly tags: ReadonlyArray<{
        readonly __typename: "NominalGenus";
        readonly genus: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $fragmentRefs": FragmentRefs<"TaxonContext_taxon" | "TaxonChildList_taxon" | "TaxonNames_taxon" | "NamesMissingField_taxon">;
    readonly " $refType": "TaxonBody_taxon";
};
export type TaxonBody_taxon$data = TaxonBody_taxon;
export type TaxonBody_taxon$key = {
    readonly " $data"?: TaxonBody_taxon$data;
    readonly " $fragmentRefs": FragmentRefs<"TaxonBody_taxon">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxonBody_taxon",
  "selections": [
    {
      "alias": "taxonRank",
      "args": null,
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "validName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Taxon",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Name",
      "kind": "LinkedField",
      "name": "baseName",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Name",
              "kind": "LinkedField",
              "name": "genus",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "type": "NominalGenus",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxonContext_taxon"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxonChildList_taxon"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "showNameDetail",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "TaxonNames_taxon"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NamesMissingField_taxon"
    }
  ],
  "type": "Taxon",
  "abstractKey": null
};
})();
(node as any).hash = '238328ea752a868a05170ce4ac7e8d14';
export default node;
