/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NameMisidentifications_name = {
    readonly oid: number;
    readonly " $fragmentRefs": FragmentRefs<"NameMisidentifications_nameInner">;
    readonly " $refType": "NameMisidentifications_name";
};
export type NameMisidentifications_name$data = NameMisidentifications_name;
export type NameMisidentifications_name$key = {
    readonly " $data"?: NameMisidentifications_name$data;
    readonly " $fragmentRefs": FragmentRefs<"NameMisidentifications_name">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCitationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCollectionDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showEtymologyDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showLocationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showNameDetail"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameMisidentifications_name",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "showCitationDetail",
          "variableName": "showCitationDetail"
        },
        {
          "kind": "Variable",
          "name": "showCollectionDetail",
          "variableName": "showCollectionDetail"
        },
        {
          "kind": "Variable",
          "name": "showEtymologyDetail",
          "variableName": "showEtymologyDetail"
        },
        {
          "kind": "Variable",
          "name": "showLocationDetail",
          "variableName": "showLocationDetail"
        },
        {
          "kind": "Variable",
          "name": "showNameDetail",
          "variableName": "showNameDetail"
        }
      ],
      "kind": "FragmentSpread",
      "name": "NameMisidentifications_nameInner"
    }
  ],
  "type": "Name",
  "abstractKey": null
};
(node as any).hash = '450f8c6383e832f57d6189ac07da0399';
export default node;
