/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LocationSubtitle_location = {
    readonly locationRegion: {
        readonly parent: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    };
    readonly " $refType": "LocationSubtitle_location";
};
export type LocationSubtitle_location$data = LocationSubtitle_location;
export type LocationSubtitle_location$key = {
    readonly " $data"?: LocationSubtitle_location$data;
    readonly " $fragmentRefs": FragmentRefs<"LocationSubtitle_location">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ModelLink_model"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationSubtitle_location",
  "selections": [
    {
      "alias": "locationRegion",
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "parent",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Location",
  "abstractKey": null
};
})();
(node as any).hash = 'f2357b9b0ebb090310eda8edd3b12a0d';
export default node;
