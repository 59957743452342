/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleType = "BOOK" | "CHAPTER" | "ERROR" | "JOURNAL" | "MISCELLANEOUS" | "PART" | "REDIRECT" | "SUPPLEMENT" | "THESIS" | "WEB" | "%future added value";
export type CitationGroupSubtitle_citationGroup = {
    readonly cgType: ArticleType;
    readonly region: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly " $refType": "CitationGroupSubtitle_citationGroup";
};
export type CitationGroupSubtitle_citationGroup$data = CitationGroupSubtitle_citationGroup;
export type CitationGroupSubtitle_citationGroup$key = {
    readonly " $data"?: CitationGroupSubtitle_citationGroup$data;
    readonly " $fragmentRefs": FragmentRefs<"CitationGroupSubtitle_citationGroup">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CitationGroupSubtitle_citationGroup",
  "selections": [
    {
      "alias": "cgType",
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ModelLink_model"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CitationGroup",
  "abstractKey": null
};
(node as any).hash = '8927dc8761bdf60cda9c60f0faa1ebbc';
export default node;
