/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AgeClass = "bite_trace" | "burrow" | "coprolite" | "egg" | "extant" | "fossil" | "holocene" | "ichno" | "recently_extinct" | "redirect" | "removed" | "track" | "%future added value";
export type Group = "family" | "genus" | "high" | "species" | "%future added value";
export type Rank = "aberratio" | "class_" | "cohort" | "division" | "domain" | "family" | "form" | "genus" | "hyperfamily" | "informal" | "informal_species" | "infraclass" | "infrafamily" | "infrakingdom" | "infraorder" | "infraphylum" | "infrasubspecific" | "infratribe" | "kingdom" | "magnorder" | "morph" | "mutation" | "natio" | "order" | "other" | "other_family" | "other_species" | "other_subgeneric" | "parvorder" | "phylum" | "race" | "root" | "semiorder" | "species" | "species_group" | "subclass" | "subcohort" | "subfamily" | "subgenus" | "subkingdom" | "suborder" | "subphylum" | "subspecies" | "subtribe" | "subvariety" | "superclass" | "supercohort" | "superfamily" | "superkingdom" | "superorder" | "superphylum" | "synonym" | "synonym_family" | "synonym_genus" | "synonym_high" | "synonym_species" | "tribe" | "unranked" | "unranked_family" | "variety" | "%future added value";
export type Status = "composite" | "dubious" | "hybrid" | "nomen_dubium" | "redirect" | "removed" | "species_inquirenda" | "spurious" | "synonym" | "valid" | "%future added value";
export type TaxonTitle_taxon = {
    readonly age: AgeClass;
    readonly validName: string;
    readonly taxonRank: Rank;
    readonly baseName: {
        readonly group: Group;
        readonly correctedOriginalName: string | null;
        readonly authorTags: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"TaxonomicAuthority_authorTags">;
        }>;
        readonly numericYear: number | null;
        readonly status: Status;
    };
    readonly " $refType": "TaxonTitle_taxon";
};
export type TaxonTitle_taxon$data = TaxonTitle_taxon;
export type TaxonTitle_taxon$key = {
    readonly " $data"?: TaxonTitle_taxon$data;
    readonly " $fragmentRefs": FragmentRefs<"TaxonTitle_taxon">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxonTitle_taxon",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "validName",
      "storageKey": null
    },
    {
      "alias": "taxonRank",
      "args": null,
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Name",
      "kind": "LinkedField",
      "name": "baseName",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "group",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correctedOriginalName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "authorTags",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TaxonomicAuthority_authorTags"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numericYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Taxon",
  "abstractKey": null
};
(node as any).hash = 'd951f9be1da2976e1ea5940d60559d12';
export default node;
