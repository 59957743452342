/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NameCommentTitle_nameComment = {
    readonly commentName: {
        readonly " $fragmentRefs": FragmentRefs<"NameTitle_name">;
    };
    readonly " $refType": "NameCommentTitle_nameComment";
};
export type NameCommentTitle_nameComment$data = NameCommentTitle_nameComment;
export type NameCommentTitle_nameComment$key = {
    readonly " $data"?: NameCommentTitle_nameComment$data;
    readonly " $fragmentRefs": FragmentRefs<"NameCommentTitle_nameComment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameCommentTitle_nameComment",
  "selections": [
    {
      "alias": "commentName",
      "args": null,
      "concreteType": "Name",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NameTitle_name"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NameComment",
  "abstractKey": null
};
(node as any).hash = '1222eee65dfef3ba435be04070aa56dd';
export default node;
