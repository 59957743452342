/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CitationGroupPatternBody_citationGroupPattern = {
    readonly oid: number;
    readonly " $refType": "CitationGroupPatternBody_citationGroupPattern";
};
export type CitationGroupPatternBody_citationGroupPattern$data = CitationGroupPatternBody_citationGroupPattern;
export type CitationGroupPatternBody_citationGroupPattern$key = {
    readonly " $data"?: CitationGroupPatternBody_citationGroupPattern$data;
    readonly " $fragmentRefs": FragmentRefs<"CitationGroupPatternBody_citationGroupPattern">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CitationGroupPatternBody_citationGroupPattern",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    }
  ],
  "type": "CitationGroupPattern",
  "abstractKey": null
};
(node as any).hash = 'de4ff9b5ada48201322eff5c3c79d440';
export default node;
