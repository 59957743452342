/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RegionKind = "canton" | "continent" | "country" | "county" | "department" | "island" | "other" | "planet" | "prefecture" | "province" | "region" | "sea" | "state" | "subnational" | "supranational" | "territory" | "%future added value";
export type RegionSubtitle_region = {
    readonly kind: RegionKind;
    readonly parent: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly " $refType": "RegionSubtitle_region";
};
export type RegionSubtitle_region$data = RegionSubtitle_region;
export type RegionSubtitle_region$key = {
    readonly " $data"?: RegionSubtitle_region$data;
    readonly " $fragmentRefs": FragmentRefs<"RegionSubtitle_region">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegionSubtitle_region",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ModelLink_model"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Region",
  "abstractKey": null
};
(node as any).hash = 'a6b5a8656ceb4d54c4f5377239ebf836';
export default node;
