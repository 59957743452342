/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NamesMissingFieldResults_queryRoot = {
    readonly taxon: {
        readonly namesMissingField: {
            readonly edges: ReadonlyArray<{
                readonly cursor: string;
            } | null>;
            readonly " $fragmentRefs": FragmentRefs<"NameList_connection">;
        } | null;
    } | null;
    readonly " $refType": "NamesMissingFieldResults_queryRoot";
};
export type NamesMissingFieldResults_queryRoot$data = NamesMissingFieldResults_queryRoot;
export type NamesMissingFieldResults_queryRoot$key = {
    readonly " $data"?: NamesMissingFieldResults_queryRoot$data;
    readonly " $fragmentRefs": FragmentRefs<"NamesMissingFieldResults_queryRoot">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 100,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "field"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "oid"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "taxon",
          "namesMissingField"
        ]
      }
    ]
  },
  "name": "NamesMissingFieldResults_queryRoot",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "oid",
          "variableName": "oid"
        }
      ],
      "concreteType": "Taxon",
      "kind": "LinkedField",
      "name": "taxon",
      "plural": false,
      "selections": [
        {
          "alias": "namesMissingField",
          "args": [
            {
              "kind": "Variable",
              "name": "field",
              "variableName": "field"
            }
          ],
          "concreteType": "NameConnection",
          "kind": "LinkedField",
          "name": "__NamesMissingFieldResults_namesMissingField_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NameEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Name",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "showCitationDetail",
                  "value": true
                },
                {
                  "kind": "Literal",
                  "name": "showNameDetail",
                  "value": true
                }
              ],
              "kind": "FragmentSpread",
              "name": "NameList_connection"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueryRoot",
  "abstractKey": null
};
(node as any).hash = '864104aeeddd2c6b3e002cdcab1097a1';
export default node;
