/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Subtitle_model = {
    readonly __typename: string;
    readonly modelCls: {
        readonly name: string;
    };
    readonly " $fragmentRefs": FragmentRefs<"NameSubtitle_name" | "CitationGroupSubtitle_citationGroup" | "CollectionSubtitle_collection" | "RegionSubtitle_region" | "PeriodSubtitle_period" | "LocationSubtitle_location" | "ClassificationEntrySubtitle_classificationEntry">;
    readonly " $refType": "Subtitle_model";
};
export type Subtitle_model$data = Subtitle_model;
export type Subtitle_model$key = {
    readonly " $data"?: Subtitle_model$data;
    readonly " $fragmentRefs": FragmentRefs<"Subtitle_model">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Subtitle_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModelCls",
      "kind": "LinkedField",
      "name": "modelCls",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameSubtitle_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CitationGroupSubtitle_citationGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionSubtitle_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionSubtitle_region"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PeriodSubtitle_period"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LocationSubtitle_location"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClassificationEntrySubtitle_classificationEntry"
    }
  ],
  "type": "Model",
  "abstractKey": "__isModel"
};
(node as any).hash = '892c7878296c6035cbfdb832d0421148';
export default node;
