/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleType = "BOOK" | "CHAPTER" | "ERROR" | "JOURNAL" | "MISCELLANEOUS" | "PART" | "REDIRECT" | "SUPPLEMENT" | "THESIS" | "WEB" | "%future added value";
export type DateSource = "decision" | "doi_published" | "doi_published_online" | "doi_published_other" | "doi_published_print" | "external" | "internal" | "%future added value";
export type ArticleBody_article = {
    readonly articleType: ArticleType | null;
    readonly authorTags: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"AuthorList_authorTags">;
    }>;
    readonly year: string | null;
    readonly numericYear: number | null;
    readonly title: string | null;
    readonly series: string | null;
    readonly volume: string | null;
    readonly issue: string | null;
    readonly startPage: string | null;
    readonly endPage: string | null;
    readonly url: string | null;
    readonly doi: string | null;
    readonly publisher: string | null;
    readonly pages: string | null;
    readonly articleName: string;
    readonly path: string | null;
    readonly parent: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly citationGroup: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly tags: ReadonlyArray<{
        readonly __typename: "ISBN";
        readonly text: string;
    } | {
        readonly __typename: "Eurobats";
        readonly text: string;
    } | {
        readonly __typename: "HDL";
        readonly text: string;
    } | {
        readonly __typename: "JSTOR";
        readonly text: string;
    } | {
        readonly __typename: "PMID";
        readonly text: string;
    } | {
        readonly __typename: "PMC";
        readonly text: string;
    } | {
        readonly __typename: "LSIDArticle";
        readonly text: string;
    } | {
        readonly __typename: "PublicationDate";
        readonly dateSource: DateSource;
        readonly date: string;
        readonly comment: string | null;
    } | {
        readonly __typename: "BiblioNoteArticle";
        readonly text: string;
    } | {
        readonly __typename: "AlternativeURL";
        readonly url: string;
    } | {
        readonly __typename: "PartialClassification";
        readonly comment: string | null;
    } | {
        readonly __typename: "InconsistentlyBinominal";
        readonly comment: string | null;
    } | {
        readonly __typename: "PlacedOnIndex";
        readonly indexSource: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly indexComment: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $fragmentRefs": FragmentRefs<"ArticleOrderedNewNames_article" | "ArticleRootClassificationEntries_article" | "ArticleArticleSet_article" | "ArticlePartiallySuppressedNames_article" | "ArticleFullySuppressedNames_article" | "ArticleConservedNames_article" | "ArticleSpellingSelections_article" | "ArticlePrioritySelections_article" | "ArticlePriorityReversals_article" | "ArticleTypeDesignations_article" | "ArticleCommissionTypeDesignations_article" | "ArticleLectotypeDesignations_article" | "ArticleNeotypeDesignations_article" | "ArticleSpecimenDetails_article" | "ArticleLocationDetails_article" | "ArticleCollectionDetails_article" | "ArticleCitationDetails_article" | "ArticleDefinitionDetails_article" | "ArticleEtymologyDetails_article" | "ArticleTypeSpeciesDetails_article" | "ArticleComments_article" | "Reference_article">;
    readonly " $refType": "ArticleBody_article";
};
export type ArticleBody_article$data = ArticleBody_article;
export type ArticleBody_article$key = {
    readonly " $data"?: ArticleBody_article$data;
    readonly " $fragmentRefs": FragmentRefs<"ArticleBody_article">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleBody_article",
  "selections": [
    {
      "alias": "articleType",
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "authorTags",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AuthorList_authorTags"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "year",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numericYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "series",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "volume",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endPage",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publisher",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pages",
      "storageKey": null
    },
    {
      "alias": "articleName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CitationGroup",
      "kind": "LinkedField",
      "name": "citationGroup",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "ISBN",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "Eurobats",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "HDL",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "JSTOR",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "PMID",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "PMC",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "LSIDArticle",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "dateSource",
              "args": null,
              "kind": "ScalarField",
              "name": "source",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "type": "PublicationDate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "BiblioNoteArticle",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "AlternativeURL",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "PartialClassification",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "InconsistentlyBinominal",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "indexSource",
              "args": null,
              "concreteType": "Article",
              "kind": "LinkedField",
              "name": "source",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": "indexComment",
              "args": null,
              "kind": "ScalarField",
              "name": "comment",
              "storageKey": null
            }
          ],
          "type": "PlacedOnIndex",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleOrderedNewNames_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleRootClassificationEntries_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleArticleSet_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticlePartiallySuppressedNames_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleFullySuppressedNames_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleConservedNames_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleSpellingSelections_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticlePrioritySelections_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticlePriorityReversals_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleTypeDesignations_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleCommissionTypeDesignations_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleLectotypeDesignations_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleNeotypeDesignations_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleSpecimenDetails_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleLocationDetails_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleCollectionDetails_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleCitationDetails_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleDefinitionDetails_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleEtymologyDetails_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleTypeSpeciesDetails_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleComments_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Reference_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();
(node as any).hash = '211f6ca0968a41b87c2629f8a2442de4';
export default node;
