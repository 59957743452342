/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StratigraphicUnitBody_stratigraphicUnit = {
    readonly oid: number;
    readonly parent: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly prev: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly region: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"StratigraphicUnitChildren_stratigraphicUnit" | "StratigraphicUnitLocations_stratigraphicUnit">;
    readonly " $refType": "StratigraphicUnitBody_stratigraphicUnit";
};
export type StratigraphicUnitBody_stratigraphicUnit$data = StratigraphicUnitBody_stratigraphicUnit;
export type StratigraphicUnitBody_stratigraphicUnit$key = {
    readonly " $data"?: StratigraphicUnitBody_stratigraphicUnit$data;
    readonly " $fragmentRefs": FragmentRefs<"StratigraphicUnitBody_stratigraphicUnit">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StratigraphicUnitBody_stratigraphicUnit",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StratigraphicUnit",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StratigraphicUnit",
      "kind": "LinkedField",
      "name": "prev",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StratigraphicUnitChildren_stratigraphicUnit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StratigraphicUnitLocations_stratigraphicUnit"
    }
  ],
  "type": "StratigraphicUnit",
  "abstractKey": null
};
})();
(node as any).hash = '715e9f343f300e9db2e5a7a66484b22d';
export default node;
