/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Title_model = {
    readonly __typename: string;
    readonly " $fragmentRefs": FragmentRefs<"ArticleTitle_article" | "ArticleCommentTitle_articleComment" | "CitationGroupTitle_citationGroup" | "CitationGroupPatternTitle_citationGroupPattern" | "ClassificationEntryTitle_classificationEntry" | "CollectionTitle_collection" | "LocationTitle_location" | "NameTitle_name" | "PersonTitle_person" | "StratigraphicUnitTitle_stratigraphicUnit" | "NameCommentTitle_nameComment" | "NameComplexTitle_nameComplex" | "NameEndingTitle_nameEnding" | "OccurrenceTitle_occurrence" | "PeriodTitle_period" | "RegionTitle_region" | "SpeciesNameComplexTitle_speciesNameComplex" | "SpeciesNameEndingTitle_speciesNameEnding" | "TaxonTitle_taxon">;
    readonly " $refType": "Title_model";
};
export type Title_model$data = Title_model;
export type Title_model$key = {
    readonly " $data"?: Title_model$data;
    readonly " $fragmentRefs": FragmentRefs<"Title_model">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Title_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleTitle_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleCommentTitle_articleComment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CitationGroupTitle_citationGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CitationGroupPatternTitle_citationGroupPattern"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClassificationEntryTitle_classificationEntry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionTitle_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LocationTitle_location"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameTitle_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonTitle_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StratigraphicUnitTitle_stratigraphicUnit"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameCommentTitle_nameComment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameComplexTitle_nameComplex"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameEndingTitle_nameEnding"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceTitle_occurrence"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PeriodTitle_period"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionTitle_region"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpeciesNameComplexTitle_speciesNameComplex"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpeciesNameEndingTitle_speciesNameEnding"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxonTitle_taxon"
    }
  ],
  "type": "Model",
  "abstractKey": "__isModel"
};
(node as any).hash = '4b05351cf7055bd9bdfa93d193ff95b2';
export default node;
