/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentKind = "authorship" | "automatic_change" | "availability" | "contents" | "definition" | "distribution" | "etymology" | "homonymy" | "nomenclature" | "other" | "removal" | "removed" | "spelling" | "structured_quote" | "taxonomy" | "type_locality" | "type_species" | "type_specimen" | "%future added value";
export type NameCommentBody_nameComment = {
    readonly oid: number;
    readonly name: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    };
    readonly commentKind: CommentKind;
    readonly date: number;
    readonly text: string;
    readonly source: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly page: string | null;
    readonly " $refType": "NameCommentBody_nameComment";
};
export type NameCommentBody_nameComment$data = NameCommentBody_nameComment;
export type NameCommentBody_nameComment$key = {
    readonly " $data"?: NameCommentBody_nameComment$data;
    readonly " $fragmentRefs": FragmentRefs<"NameCommentBody_nameComment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameCommentBody_nameComment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Name",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "commentKind",
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    }
  ],
  "type": "NameComment",
  "abstractKey": null
};
})();
(node as any).hash = '1aed3e5e78f66d95cba052727e9de95c';
export default node;
