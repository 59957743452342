/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StratigraphicUnitTitle_stratigraphicUnit = {
    readonly suName: string;
    readonly " $refType": "StratigraphicUnitTitle_stratigraphicUnit";
};
export type StratigraphicUnitTitle_stratigraphicUnit$data = StratigraphicUnitTitle_stratigraphicUnit;
export type StratigraphicUnitTitle_stratigraphicUnit$key = {
    readonly " $data"?: StratigraphicUnitTitle_stratigraphicUnit$data;
    readonly " $fragmentRefs": FragmentRefs<"StratigraphicUnitTitle_stratigraphicUnit">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StratigraphicUnitTitle_stratigraphicUnit",
  "selections": [
    {
      "alias": "suName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "StratigraphicUnit",
  "abstractKey": null
};
(node as any).hash = '196a1b70a44dd686b76a90174c9297fe';
export default node;
