/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NameJuniorSecondaryHomonyms_nameInner = {
    readonly oid: number;
    readonly numJuniorSecondaryHomonyms: number;
    readonly juniorSecondaryHomonyms: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly oid: number;
            } | null;
        } | null>;
        readonly " $fragmentRefs": FragmentRefs<"NameList_connection">;
    } | null;
    readonly " $refType": "NameJuniorSecondaryHomonyms_nameInner";
};
export type NameJuniorSecondaryHomonyms_nameInner$data = NameJuniorSecondaryHomonyms_nameInner;
export type NameJuniorSecondaryHomonyms_nameInner$key = {
    readonly " $data"?: NameJuniorSecondaryHomonyms_nameInner$data;
    readonly " $fragmentRefs": FragmentRefs<"NameJuniorSecondaryHomonyms_nameInner">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "oid",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCitationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCollectionDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showEtymologyDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showLocationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showNameDetail"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "juniorSecondaryHomonyms"
        ]
      }
    ]
  },
  "name": "NameJuniorSecondaryHomonyms_nameInner",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numJuniorSecondaryHomonyms",
      "storageKey": null
    },
    {
      "alias": "juniorSecondaryHomonyms",
      "args": null,
      "concreteType": "NameConnection",
      "kind": "LinkedField",
      "name": "__NameJuniorSecondaryHomonyms_juniorSecondaryHomonyms_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NameEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Name",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "showCitationDetail",
              "variableName": "showCitationDetail"
            },
            {
              "kind": "Variable",
              "name": "showCollectionDetail",
              "variableName": "showCollectionDetail"
            },
            {
              "kind": "Variable",
              "name": "showEtymologyDetail",
              "variableName": "showEtymologyDetail"
            },
            {
              "kind": "Variable",
              "name": "showLocationDetail",
              "variableName": "showLocationDetail"
            },
            {
              "kind": "Variable",
              "name": "showNameDetail",
              "variableName": "showNameDetail"
            }
          ],
          "kind": "FragmentSpread",
          "name": "NameList_connection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Name",
  "abstractKey": null
};
})();
(node as any).hash = '073fbdd4692cf3e39cd2ac2c0d644d9f';
export default node;
