/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionTitle_collection = {
    readonly label: string;
    readonly collectionName: string;
    readonly " $refType": "CollectionTitle_collection";
};
export type CollectionTitle_collection$data = CollectionTitle_collection;
export type CollectionTitle_collection$key = {
    readonly " $data"?: CollectionTitle_collection$data;
    readonly " $fragmentRefs": FragmentRefs<"CollectionTitle_collection">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionTitle_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": "collectionName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Collection",
  "abstractKey": null
};
(node as any).hash = '3713435f8b62f68ea6f46db91cc02e8a';
export default node;
