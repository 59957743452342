/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleType = "BOOK" | "CHAPTER" | "ERROR" | "JOURNAL" | "MISCELLANEOUS" | "PART" | "REDIRECT" | "SUPPLEMENT" | "THESIS" | "WEB" | "%future added value";
export type Reference_article = {
    readonly type: ArticleType | null;
    readonly title: string | null;
    readonly startPage: string | null;
    readonly endPage: string | null;
    readonly oid: number;
    readonly parent: {
        readonly " $fragmentRefs": FragmentRefs<"SimpleReference_article">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"ReferencePrefix_article" | "ReferenceSuffix_article" | "SimpleReference_article">;
    readonly " $refType": "Reference_article";
};
export type Reference_article$data = Reference_article;
export type Reference_article$key = {
    readonly " $data"?: Reference_article$data;
    readonly " $fragmentRefs": FragmentRefs<"Reference_article">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SimpleReference_article"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Reference_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReferencePrefix_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReferenceSuffix_article"
    },
    (v0/*: any*/)
  ],
  "type": "Article",
  "abstractKey": null
};
})();
(node as any).hash = 'a93aba1620a9a420073a248a350a9078';
export default node;
