/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NamesMissingField_taxon = {
    readonly oid: number;
    readonly " $refType": "NamesMissingField_taxon";
};
export type NamesMissingField_taxon$data = NamesMissingField_taxon;
export type NamesMissingField_taxon$key = {
    readonly " $data"?: NamesMissingField_taxon$data;
    readonly " $fragmentRefs": FragmentRefs<"NamesMissingField_taxon">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NamesMissingField_taxon",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    }
  ],
  "type": "Taxon",
  "abstractKey": null
};
(node as any).hash = 'a4f6e80dae9540168881781145bca66e';
export default node;
