/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModelLinkNoExtra_model = {
    readonly oid: number;
    readonly callSign: string;
    readonly " $fragmentRefs": FragmentRefs<"Title_model">;
    readonly " $refType": "ModelLinkNoExtra_model";
};
export type ModelLinkNoExtra_model$data = ModelLinkNoExtra_model;
export type ModelLinkNoExtra_model$key = {
    readonly " $data"?: ModelLinkNoExtra_model$data;
    readonly " $fragmentRefs": FragmentRefs<"ModelLinkNoExtra_model">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelLinkNoExtra_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callSign",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Title_model"
    }
  ],
  "type": "Model",
  "abstractKey": "__isModel"
};
(node as any).hash = '45c5e74867dec9c408d00017f3315cfb';
export default node;
