/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AuthorList_authorTags = ReadonlyArray<{
    readonly person?: {
        readonly oid: number;
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    };
    readonly " $refType": "AuthorList_authorTags";
}>;
export type AuthorList_authorTags$data = AuthorList_authorTags;
export type AuthorList_authorTags$key = ReadonlyArray<{
    readonly " $data"?: AuthorList_authorTags$data;
    readonly " $fragmentRefs": FragmentRefs<"AuthorList_authorTags">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AuthorList_authorTags",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "oid",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ModelLink_model"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Author",
      "abstractKey": null
    }
  ],
  "type": "AuthorTag",
  "abstractKey": "__isAuthorTag"
};
(node as any).hash = 'c2be1ec1c9a35e07f02a7140be030550';
export default node;
