/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleTitle_article = {
    readonly authorTags: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"TaxonomicAuthority_authorTags">;
    }>;
    readonly numericYear: number | null;
    readonly " $refType": "ArticleTitle_article";
};
export type ArticleTitle_article$data = ArticleTitle_article;
export type ArticleTitle_article$key = {
    readonly " $data"?: ArticleTitle_article$data;
    readonly " $fragmentRefs": FragmentRefs<"ArticleTitle_article">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleTitle_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "authorTags",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaxonomicAuthority_authorTags"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numericYear",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
(node as any).hash = 'abd2a460880184004bee21b3e504e98f';
export default node;
