/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleType = "BOOK" | "CHAPTER" | "ERROR" | "JOURNAL" | "MISCELLANEOUS" | "PART" | "REDIRECT" | "SUPPLEMENT" | "THESIS" | "WEB" | "%future added value";
export type NamingConvention = "ancient" | "burmese" | "chinese" | "dutch" | "english" | "english_peer" | "french" | "general" | "german" | "hungarian" | "italian" | "japanese" | "korean" | "mongolian" | "organization" | "other" | "pinyin" | "portuguese" | "russian" | "spanish" | "turkish" | "ukrainian" | "unspecified" | "vietnamese" | "%future added value";
export type ReferencePrefix_article = {
    readonly type: ArticleType | null;
    readonly authorTags: ReadonlyArray<{
        readonly person?: {
            readonly id: string;
            readonly familyName: string;
            readonly givenNames: string | null;
            readonly initials: string | null;
            readonly tussenvoegsel: string | null;
            readonly suffix: string | null;
            readonly namingConvention: NamingConvention;
        };
    }>;
    readonly numericYear: number | null;
    readonly oid: number;
    readonly title: string | null;
    readonly " $refType": "ReferencePrefix_article";
};
export type ReferencePrefix_article$data = ReferencePrefix_article;
export type ReferencePrefix_article$key = {
    readonly " $data"?: ReferencePrefix_article$data;
    readonly " $fragmentRefs": FragmentRefs<"ReferencePrefix_article">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferencePrefix_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "authorTags",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "familyName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "givenNames",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "initials",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tussenvoegsel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "suffix",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "namingConvention",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Author",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numericYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
(node as any).hash = '78a648e4e97f002c21f101cee9b10984';
export default node;
