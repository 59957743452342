/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SpeciesNameEndingTitle_speciesNameEnding = {
    readonly ending: string;
    readonly " $refType": "SpeciesNameEndingTitle_speciesNameEnding";
};
export type SpeciesNameEndingTitle_speciesNameEnding$data = SpeciesNameEndingTitle_speciesNameEnding;
export type SpeciesNameEndingTitle_speciesNameEnding$key = {
    readonly " $data"?: SpeciesNameEndingTitle_speciesNameEnding$data;
    readonly " $fragmentRefs": FragmentRefs<"SpeciesNameEndingTitle_speciesNameEnding">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpeciesNameEndingTitle_speciesNameEnding",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ending",
      "storageKey": null
    }
  ],
  "type": "SpeciesNameEnding",
  "abstractKey": null
};
(node as any).hash = '6af89b057762206953a25848ec0ca4ae';
export default node;
