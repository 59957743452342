/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionBody_collection = {
    readonly numChildren: number;
    readonly parent: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly tags: ReadonlyArray<{
        readonly __typename: "CollectionDatabase";
        readonly citation: {
            readonly name: string;
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "TypeCatalog";
        readonly citation: {
            readonly name: string;
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly coverage: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $fragmentRefs": FragmentRefs<"CollectionTypeSpecimens_collection" | "CollectionSharedSpecimens_collection" | "CollectionFormerSpecimens_collection" | "CollectionExtraSpecimens_collection" | "CollectionFutureSpecimens_collection" | "CollectionProbableSpecimens_collection" | "CollectionGuessedSpecimens_collection" | "CollectionAssociatedPeople_collection" | "CollectionChildren_collection">;
    readonly " $refType": "CollectionBody_collection";
};
export type CollectionBody_collection$data = CollectionBody_collection;
export type CollectionBody_collection$key = {
    readonly " $data"?: CollectionBody_collection$data;
    readonly " $fragmentRefs": FragmentRefs<"CollectionBody_collection">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ModelLink_model"
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "citation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionBody_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numChildren",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "comment",
              "storageKey": null
            }
          ],
          "type": "CollectionDatabase",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverage",
              "storageKey": null
            }
          ],
          "type": "TypeCatalog",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionTypeSpecimens_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionSharedSpecimens_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionFormerSpecimens_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionExtraSpecimens_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionFutureSpecimens_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionProbableSpecimens_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionGuessedSpecimens_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionAssociatedPeople_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionChildren_collection"
    }
  ],
  "type": "Collection",
  "abstractKey": null
};
})();
(node as any).hash = '59d88991df16086ec988bf192a8f7646';
export default node;
