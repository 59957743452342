/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RegionTitle_region = {
    readonly regionName: string;
    readonly " $refType": "RegionTitle_region";
};
export type RegionTitle_region$data = RegionTitle_region;
export type RegionTitle_region$key = {
    readonly " $data"?: RegionTitle_region$data;
    readonly " $fragmentRefs": FragmentRefs<"RegionTitle_region">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegionTitle_region",
  "selections": [
    {
      "alias": "regionName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Region",
  "abstractKey": null
};
(node as any).hash = '2e6903e2956a558d2ff65082efd0e9ab';
export default node;
