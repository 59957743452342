/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CitationGroupBody_citationGroup = {
    readonly target: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly tags: ReadonlyArray<{
        readonly __typename: "ISSN";
        readonly text: string;
    } | {
        readonly __typename: "ISSNOnline";
        readonly text: string;
    } | {
        readonly __typename: "BHLBibliography";
        readonly text: string;
    } | {
        readonly __typename: "CitationGroupURL";
        readonly text: string;
    } | {
        readonly __typename: "Predecessor";
        readonly cg: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "YearRange";
        readonly start: string;
        readonly end: string | null;
    } | {
        readonly __typename: "DatingTools";
        readonly text: string;
    } | {
        readonly __typename: "BiblioNote";
        readonly text: string;
    } | {
        readonly __typename: "CitationGroupComment";
        readonly text: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly issueDateSet: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly series: string | null;
                readonly volume: string;
                readonly issue: string | null;
                readonly startPage: string | null;
                readonly endPage: string | null;
                readonly date: string;
                readonly tags: ReadonlyArray<{
                    readonly __typename: "CommentIssueDate";
                    readonly text: string;
                    readonly optionalSource: {
                        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
                    } | null;
                } | {
                    /*This will never be '%other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                }>;
            } | null;
        } | null>;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CitationGroupRedirects_citationGroup" | "CitationGroupOrderedArticles_citationGroup" | "CitationGroupOrderedNames_citationGroup">;
    readonly " $refType": "CitationGroupBody_citationGroup";
};
export type CitationGroupBody_citationGroup$data = CitationGroupBody_citationGroup;
export type CitationGroupBody_citationGroup$key = {
    readonly " $data"?: CitationGroupBody_citationGroup$data;
    readonly " $fragmentRefs": FragmentRefs<"CitationGroupBody_citationGroup">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CitationGroupBody_citationGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CitationGroup",
      "kind": "LinkedField",
      "name": "target",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "ISSN",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "ISSNOnline",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "BHLBibliography",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "CitationGroupURL",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CitationGroup",
              "kind": "LinkedField",
              "name": "cg",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "type": "Predecessor",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "start",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "end",
              "storageKey": null
            }
          ],
          "type": "YearRange",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "DatingTools",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "BiblioNote",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "CitationGroupComment",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "IssueDateConnection",
      "kind": "LinkedField",
      "name": "issueDateSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IssueDateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IssueDate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "series",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "volume",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "issue",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "tags",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Article",
                          "kind": "LinkedField",
                          "name": "optionalSource",
                          "plural": false,
                          "selections": (v0/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "CommentIssueDate",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "issueDateSet(first:1000)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CitationGroupRedirects_citationGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CitationGroupOrderedArticles_citationGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CitationGroupOrderedNames_citationGroup"
    }
  ],
  "type": "CitationGroup",
  "abstractKey": null
};
})();
(node as any).hash = 'a323e157696369ceed0cc8f2ee0924c2';
export default node;
