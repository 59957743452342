/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PeriodBody_period = {
    readonly oid: number;
    readonly parent: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly prev: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly next: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly region: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"PeriodChildren_period" | "PeriodLocations_period">;
    readonly " $refType": "PeriodBody_period";
};
export type PeriodBody_period$data = PeriodBody_period;
export type PeriodBody_period$key = {
    readonly " $data"?: PeriodBody_period$data;
    readonly " $fragmentRefs": FragmentRefs<"PeriodBody_period">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PeriodBody_period",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Period",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Period",
      "kind": "LinkedField",
      "name": "prev",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Period",
      "kind": "LinkedField",
      "name": "next",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PeriodChildren_period"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PeriodLocations_period"
    }
  ],
  "type": "Period",
  "abstractKey": null
};
})();
(node as any).hash = '12f3f086d1dc2bba753043d7c6d81e0d';
export default node;
