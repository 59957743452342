/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SpeciesNameComplexTitle_speciesNameComplex = {
    readonly label: string;
    readonly " $refType": "SpeciesNameComplexTitle_speciesNameComplex";
};
export type SpeciesNameComplexTitle_speciesNameComplex$data = SpeciesNameComplexTitle_speciesNameComplex;
export type SpeciesNameComplexTitle_speciesNameComplex$key = {
    readonly " $data"?: SpeciesNameComplexTitle_speciesNameComplex$data;
    readonly " $fragmentRefs": FragmentRefs<"SpeciesNameComplexTitle_speciesNameComplex">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpeciesNameComplexTitle_speciesNameComplex",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "SpeciesNameComplex",
  "abstractKey": null
};
(node as any).hash = '042c65d60c235c65bbb8fcc271ec5b03';
export default node;
