/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CitationGroupRedirects_citationGroup = {
    readonly oid: number;
    readonly numRedirects: number;
    readonly redirects: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly oid: number;
                readonly __typename: string;
                readonly id?: string;
                readonly numTypeSpecimens?: number;
                readonly numChildren?: number;
                readonly numNames?: number;
                readonly numTypeLocalities?: number;
                readonly numLocations?: number;
                readonly " $fragmentRefs": FragmentRefs<"ModelListEntry_model">;
            } | null;
        } | null>;
    } | null;
    readonly " $refType": "CitationGroupRedirects_citationGroup";
};
export type CitationGroupRedirects_citationGroup$data = CitationGroupRedirects_citationGroup;
export type CitationGroupRedirects_citationGroup$key = {
    readonly " $data"?: CitationGroupRedirects_citationGroup$data;
    readonly " $fragmentRefs": FragmentRefs<"CitationGroupRedirects_citationGroup">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "oid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numChildren",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "numLocations",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "redirects"
        ]
      }
    ]
  },
  "name": "CitationGroupRedirects_citationGroup",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numRedirects",
      "storageKey": null
    },
    {
      "alias": "redirects",
      "args": null,
      "concreteType": "CitationGroupConnection",
      "kind": "LinkedField",
      "name": "__CitationGroupRedirects_redirects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CitationGroupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CitationGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ModelListEntry_model"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        }
                      ],
                      "type": "Node",
                      "abstractKey": "__isNode"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "numTypeSpecimens",
                          "storageKey": null
                        }
                      ],
                      "type": "Collection",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "numNames",
                          "storageKey": null
                        }
                      ],
                      "type": "Taxon",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "numTypeLocalities",
                          "storageKey": null
                        }
                      ],
                      "type": "Location",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v2/*: any*/),
                      "type": "Region",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v2/*: any*/),
                      "type": "Period",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v2/*: any*/),
                      "type": "StratigraphicUnit",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "type": "ClassificationEntry",
                      "abstractKey": null
                    }
                  ],
                  "type": "Model",
                  "abstractKey": "__isModel"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CitationGroup",
  "abstractKey": null
};
})();
(node as any).hash = 'c6c8b10a8c29677c8b4b9bafdd1aecf0';
export default node;
