/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NamingConvention = "ancient" | "burmese" | "chinese" | "dutch" | "english" | "english_peer" | "french" | "general" | "german" | "hungarian" | "italian" | "japanese" | "korean" | "mongolian" | "organization" | "other" | "pinyin" | "portuguese" | "russian" | "spanish" | "turkish" | "ukrainian" | "unspecified" | "vietnamese" | "%future added value";
export type TaxonomicAuthority_authorTags = ReadonlyArray<{
    readonly person?: {
        readonly id: string;
        readonly familyName: string;
        readonly namingConvention: NamingConvention;
        readonly tussenvoegsel: string | null;
    };
    readonly " $refType": "TaxonomicAuthority_authorTags";
}>;
export type TaxonomicAuthority_authorTags$data = TaxonomicAuthority_authorTags;
export type TaxonomicAuthority_authorTags$key = ReadonlyArray<{
    readonly " $data"?: TaxonomicAuthority_authorTags$data;
    readonly " $fragmentRefs": FragmentRefs<"TaxonomicAuthority_authorTags">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TaxonomicAuthority_authorTags",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "familyName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "namingConvention",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tussenvoegsel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Author",
      "abstractKey": null
    }
  ],
  "type": "AuthorTag",
  "abstractKey": "__isAuthorTag"
};
(node as any).hash = '9504350bfec9fddedec06b597b59a0bf';
export default node;
