/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PeriodTitle_period = {
    readonly periodName: string;
    readonly " $refType": "PeriodTitle_period";
};
export type PeriodTitle_period$data = PeriodTitle_period;
export type PeriodTitle_period$key = {
    readonly " $data"?: PeriodTitle_period$data;
    readonly " $fragmentRefs": FragmentRefs<"PeriodTitle_period">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PeriodTitle_period",
  "selections": [
    {
      "alias": "periodName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Period",
  "abstractKey": null
};
(node as any).hash = '77968aa8b2ca161dd13b58f1f9cf2e75';
export default node;
