/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OccurrenceBody_occurrence = {
    readonly oid: number;
    readonly " $refType": "OccurrenceBody_occurrence";
};
export type OccurrenceBody_occurrence$data = OccurrenceBody_occurrence;
export type OccurrenceBody_occurrence$key = {
    readonly " $data"?: OccurrenceBody_occurrence$data;
    readonly " $fragmentRefs": FragmentRefs<"OccurrenceBody_occurrence">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OccurrenceBody_occurrence",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
(node as any).hash = 'dcf6411fe4609ad9f698ed6f13bc264c';
export default node;
