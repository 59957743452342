/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReferenceSuffix_article = {
    readonly url: string | null;
    readonly doi: string | null;
    readonly " $refType": "ReferenceSuffix_article";
};
export type ReferenceSuffix_article$data = ReferenceSuffix_article;
export type ReferenceSuffix_article$key = {
    readonly " $data"?: ReferenceSuffix_article$data;
    readonly " $fragmentRefs": FragmentRefs<"ReferenceSuffix_article">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferenceSuffix_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doi",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
(node as any).hash = 'a4d1e874c2eae2a8732fad9f9e8883a7';
export default node;
