/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SpeciesNameKind = "adjective" | "ambiguous_noun" | "genitive" | "genitive_adjective" | "non_latin" | "noun_in_apposition" | "patronym_feminine" | "patronym_feminine_plural" | "patronym_latin" | "patronym_masculine" | "patronym_masculine_plural" | "unknown" | "%future added value";
export type SpeciesNameComplexBody_speciesNameComplex = {
    readonly stem: string | null;
    readonly sncKind: SpeciesNameKind;
    readonly masculineEnding: string;
    readonly feminineEnding: string;
    readonly neuterEnding: string;
    readonly comment: string | null;
    readonly " $fragmentRefs": FragmentRefs<"SpeciesNameComplexNames_speciesNameComplex">;
    readonly " $refType": "SpeciesNameComplexBody_speciesNameComplex";
};
export type SpeciesNameComplexBody_speciesNameComplex$data = SpeciesNameComplexBody_speciesNameComplex;
export type SpeciesNameComplexBody_speciesNameComplex$key = {
    readonly " $data"?: SpeciesNameComplexBody_speciesNameComplex$data;
    readonly " $fragmentRefs": FragmentRefs<"SpeciesNameComplexBody_speciesNameComplex">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpeciesNameComplexBody_speciesNameComplex",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stem",
      "storageKey": null
    },
    {
      "alias": "sncKind",
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "masculineEnding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feminineEnding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "neuterEnding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpeciesNameComplexNames_speciesNameComplex"
    }
  ],
  "type": "SpeciesNameComplex",
  "abstractKey": null
};
(node as any).hash = 'ffe79f1560e2161f55868521b3d74272';
export default node;
