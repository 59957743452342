/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NameEndingBody_nameEnding = {
    readonly oid: number;
    readonly " $refType": "NameEndingBody_nameEnding";
};
export type NameEndingBody_nameEnding$data = NameEndingBody_nameEnding;
export type NameEndingBody_nameEnding$key = {
    readonly " $data"?: NameEndingBody_nameEnding$data;
    readonly " $fragmentRefs": FragmentRefs<"NameEndingBody_nameEnding">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameEndingBody_nameEnding",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    }
  ],
  "type": "NameEnding",
  "abstractKey": null
};
(node as any).hash = 'af53be5c3724d35dc93bc4b7bdb07e3a';
export default node;
