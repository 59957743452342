/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NameIncorrectOriginalSpellings_name = {
    readonly oid: number;
    readonly " $fragmentRefs": FragmentRefs<"NameIncorrectOriginalSpellings_nameInner">;
    readonly " $refType": "NameIncorrectOriginalSpellings_name";
};
export type NameIncorrectOriginalSpellings_name$data = NameIncorrectOriginalSpellings_name;
export type NameIncorrectOriginalSpellings_name$key = {
    readonly " $data"?: NameIncorrectOriginalSpellings_name$data;
    readonly " $fragmentRefs": FragmentRefs<"NameIncorrectOriginalSpellings_name">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCitationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCollectionDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showEtymologyDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showLocationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showNameDetail"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameIncorrectOriginalSpellings_name",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "showCitationDetail",
          "variableName": "showCitationDetail"
        },
        {
          "kind": "Variable",
          "name": "showCollectionDetail",
          "variableName": "showCollectionDetail"
        },
        {
          "kind": "Variable",
          "name": "showEtymologyDetail",
          "variableName": "showEtymologyDetail"
        },
        {
          "kind": "Variable",
          "name": "showLocationDetail",
          "variableName": "showLocationDetail"
        },
        {
          "kind": "Variable",
          "name": "showNameDetail",
          "variableName": "showNameDetail"
        }
      ],
      "kind": "FragmentSpread",
      "name": "NameIncorrectOriginalSpellings_nameInner"
    }
  ],
  "type": "Name",
  "abstractKey": null
};
(node as any).hash = '7c19c5f5937a34200ea3438ce18ef06d';
export default node;
