/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DocsQueryVariables = {
    path: string;
};
export type DocsQueryResponse = {
    readonly documentation: string | null;
};
export type DocsQuery = {
    readonly response: DocsQueryResponse;
    readonly variables: DocsQueryVariables;
};



/*
query DocsQuery(
  $path: String!
) {
  documentation(path: $path)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "path"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "path",
        "variableName": "path"
      }
    ],
    "kind": "ScalarField",
    "name": "documentation",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocsQuery",
    "selections": (v1/*: any*/),
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0b400938946b136e1b1a9bcc64fa1228",
    "id": null,
    "metadata": {},
    "name": "DocsQuery",
    "operationKind": "query",
    "text": "query DocsQuery(\n  $path: String!\n) {\n  documentation(path: $path)\n}\n"
  }
};
})();
(node as any).hash = '3f8049222192828f0c8d20e102d30d52';
export default node;
