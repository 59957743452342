/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleType = "BOOK" | "CHAPTER" | "ERROR" | "JOURNAL" | "MISCELLANEOUS" | "PART" | "REDIRECT" | "SUPPLEMENT" | "THESIS" | "WEB" | "%future added value";
export type SimpleReference_article = {
    readonly type: ArticleType | null;
    readonly citationGroup: {
        readonly name: string;
    } | null;
    readonly series: string | null;
    readonly volume: string | null;
    readonly issue: string | null;
    readonly startPage: string | null;
    readonly endPage: string | null;
    readonly pages: string | null;
    readonly publisher: string | null;
    readonly " $fragmentRefs": FragmentRefs<"ReferencePrefix_article" | "ReferenceSuffix_article">;
    readonly " $refType": "SimpleReference_article";
};
export type SimpleReference_article$data = SimpleReference_article;
export type SimpleReference_article$key = {
    readonly " $data"?: SimpleReference_article$data;
    readonly " $fragmentRefs": FragmentRefs<"SimpleReference_article">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SimpleReference_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CitationGroup",
      "kind": "LinkedField",
      "name": "citationGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "series",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "volume",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publisher",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReferencePrefix_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReferenceSuffix_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
(node as any).hash = 'e5553a30e2bcb78495739a54afc6ef63';
export default node;
