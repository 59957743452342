/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NameEndingTitle_nameEnding = {
    readonly ending: string;
    readonly " $refType": "NameEndingTitle_nameEnding";
};
export type NameEndingTitle_nameEnding$data = NameEndingTitle_nameEnding;
export type NameEndingTitle_nameEnding$key = {
    readonly " $data"?: NameEndingTitle_nameEnding$data;
    readonly " $fragmentRefs": FragmentRefs<"NameEndingTitle_nameEnding">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameEndingTitle_nameEnding",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ending",
      "storageKey": null
    }
  ],
  "type": "NameEnding",
  "abstractKey": null
};
(node as any).hash = 'a5f9306dc5a619150d1f187ea4ba792b';
export default node;
