/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AltitudeUnit = "ft" | "m" | "%future added value";
export type SpecimenAge = "adult" | "embryo" | "juvenile" | "larva" | "subadult" | "%future added value";
export type SpecimenGender = "female" | "hermaphrodite" | "male" | "unknown" | "%future added value";
export type SpecimenOrgan = "antler" | "astragalus" | "baculum" | "calcaneum" | "carpal" | "carpometacarpal" | "caudal_tube" | "clavicle" | "coracoid" | "dentary" | "egg" | "femur" | "fibula" | "frontlet" | "furcula" | "gastralia" | "girdle" | "horn_core" | "humerus" | "hyoid" | "ilium" | "in_alcohol" | "interclavicle" | "ischium" | "limb" | "mandible" | "manus" | "maxilla" | "metacarpal" | "metapodial" | "metatarsal" | "navicular" | "osteoderm" | "other" | "palate" | "patella" | "pelvis" | "pes" | "petrosal" | "phalanx" | "phalanx_manus" | "phalanx_pes" | "postcranial_skeleton" | "predentary" | "premaxilla" | "prepubis" | "pubis" | "radius" | "rib" | "scapula" | "scapulocoracoid" | "shell" | "skeleton" | "skin" | "skull" | "sternum" | "tarsometatarsus" | "tibia" | "tibiotarsus" | "tissue_sample" | "tooth" | "ulna" | "vertebra" | "whole_animal" | "%future added value";
export type NameTypeTags_name = {
    readonly typeTags: ReadonlyArray<{
        readonly __typename: "Age";
        readonly age: SpecimenAge;
    } | {
        readonly __typename: "Altitude";
        readonly altitude: string;
        readonly unit: AltitudeUnit;
    } | {
        readonly __typename: "CitationDetail";
        readonly text: string;
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "CollectionDetail";
        readonly text: string;
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "CollectedBy";
        readonly person: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "CommissionTypeDesignation";
        readonly opinion: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly type: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "Coordinates";
        readonly latitude: string;
        readonly longitude: string;
    } | {
        readonly __typename: "Date";
        readonly date: string;
    } | {
        readonly __typename: "DefinitionDetail";
        readonly text: string;
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "EtymologyDetail";
        readonly text: string;
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "Gender";
        readonly gender: SpecimenGender;
    } | {
        readonly __typename: "GenusCoelebs";
        readonly comments: string | null;
    } | {
        readonly __typename: "Habitat";
        readonly text: string;
    } | {
        readonly __typename: "Host";
        readonly hostName: string;
    } | {
        readonly __typename: "IncludedSpecies";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "LectotypeDesignation";
        readonly optionalSource: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        } | null;
        readonly lectotype: string;
        readonly valid: boolean;
        readonly comment: string | null;
    } | {
        readonly __typename: "LocationDetail";
        readonly text: string;
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "NeotypeDesignation";
        readonly optionalSource: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        } | null;
        readonly neotype: string;
        readonly valid: boolean;
        readonly comment: string | null;
    } | {
        readonly __typename: "Organ";
        readonly organ: SpecimenOrgan;
        readonly detail: string | null;
        readonly condition: string | null;
    } | {
        readonly __typename: "ProbableRepository";
        readonly repository: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly reasoning: string | null;
    } | {
        readonly __typename: "GuessedRepository";
        readonly repository: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly score: number;
    } | {
        readonly __typename: "Repository";
        readonly repository: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "FormerRepository";
        readonly repository: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "FutureRepository";
        readonly repository: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "ExtraRepository";
        readonly repository: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "SourceDetail";
        readonly text: string;
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "SpecimenDetail";
        readonly text: string;
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "StratigraphyDetail";
        readonly text: string;
    } | {
        readonly __typename: "TypeDesignation";
        readonly optionalSource: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        } | null;
        readonly type: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "TypeSpeciesDetail";
        readonly text: string;
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "NamedAfter";
        readonly person: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "TextualOriginalRank";
        readonly text: string;
    } | {
        readonly __typename: "LSIDName";
        readonly text: string;
    } | {
        readonly __typename: "TypeSpecimenLink";
        readonly url: string;
    } | {
        readonly __typename: "TypeSpecimenLinkFor";
        readonly url: string;
        readonly specimen: string;
    } | {
        readonly __typename: "AuthorityPageLink";
        readonly url: string;
        readonly page: string;
    } | {
        readonly __typename: "PhyloCodeNumber";
        readonly number: number;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $refType": "NameTypeTags_name";
};
export type NameTypeTags_name$data = NameTypeTags_name;
export type NameTypeTags_name$key = {
    readonly " $data"?: NameTypeTags_name$data;
    readonly " $fragmentRefs": FragmentRefs<"NameTypeTags_name">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Article",
    "kind": "LinkedField",
    "name": "source",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Person",
    "kind": "LinkedField",
    "name": "person",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Name",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v5 = [
  (v0/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "optionalSource",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valid",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Collection",
  "kind": "LinkedField",
  "name": "repository",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameTypeTags_name",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeTags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "age",
              "storageKey": null
            }
          ],
          "type": "Age",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "altitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            }
          ],
          "type": "Altitude",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "CitationDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "CollectionDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "CollectedBy",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Article",
              "kind": "LinkedField",
              "name": "opinion",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "type": "CommissionTypeDesignation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            }
          ],
          "type": "Coordinates",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            }
          ],
          "type": "Date",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "DefinitionDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "EtymologyDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gender",
              "storageKey": null
            }
          ],
          "type": "Gender",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "comments",
              "storageKey": null
            }
          ],
          "type": "GenusCoelebs",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "Habitat",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "hostName",
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "type": "Host",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Name",
              "kind": "LinkedField",
              "name": "name",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "type": "IncludedSpecies",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lectotype",
              "storageKey": null
            },
            (v8/*: any*/),
            (v6/*: any*/)
          ],
          "type": "LectotypeDesignation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "LocationDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "neotype",
              "storageKey": null
            },
            (v8/*: any*/),
            (v6/*: any*/)
          ],
          "type": "NeotypeDesignation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "organ",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "detail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "condition",
              "storageKey": null
            }
          ],
          "type": "Organ",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reasoning",
              "storageKey": null
            }
          ],
          "type": "ProbableRepository",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "score",
              "storageKey": null
            }
          ],
          "type": "GuessedRepository",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v10/*: any*/),
          "type": "Repository",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v10/*: any*/),
          "type": "FormerRepository",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v10/*: any*/),
          "type": "FutureRepository",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v10/*: any*/),
          "type": "ExtraRepository",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "SourceDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "SpecimenDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "StratigraphyDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v7/*: any*/),
            (v4/*: any*/),
            (v6/*: any*/)
          ],
          "type": "TypeDesignation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "TypeSpeciesDetail",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "NamedAfter",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "TextualOriginalRank",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "LSIDName",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v11/*: any*/)
          ],
          "type": "TypeSpecimenLink",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "specimen",
              "storageKey": null
            }
          ],
          "type": "TypeSpecimenLinkFor",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "page",
              "storageKey": null
            }
          ],
          "type": "AuthorityPageLink",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "type": "PhyloCodeNumber",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Name",
  "abstractKey": null
};
})();
(node as any).hash = '2fa9fb3b8f3b7dc5a2dd95ba49d9dd9f';
export default node;
