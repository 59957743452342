/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LocationTitle_location = {
    readonly locationName: string;
    readonly " $refType": "LocationTitle_location";
};
export type LocationTitle_location$data = LocationTitle_location;
export type LocationTitle_location$key = {
    readonly " $data"?: LocationTitle_location$data;
    readonly " $fragmentRefs": FragmentRefs<"LocationTitle_location">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationTitle_location",
  "selections": [
    {
      "alias": "locationName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Location",
  "abstractKey": null
};
(node as any).hash = '6ea000cf2e5125ede3b37cdcbcc699bd';
export default node;
