/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NamingConvention = "ancient" | "burmese" | "chinese" | "dutch" | "english" | "english_peer" | "french" | "general" | "german" | "hungarian" | "italian" | "japanese" | "korean" | "mongolian" | "organization" | "other" | "pinyin" | "portuguese" | "russian" | "spanish" | "turkish" | "ukrainian" | "unspecified" | "vietnamese" | "%future added value";
export type PersonBody_person = {
    readonly familyName: string;
    readonly givenNames: string | null;
    readonly initials: string | null;
    readonly suffix: string | null;
    readonly tussenvoegsel: string | null;
    readonly birth: string | null;
    readonly death: string | null;
    readonly namingConvention: NamingConvention;
    readonly bio: string | null;
    readonly target: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly tags: ReadonlyArray<{
        readonly __typename: "Wiki";
        readonly text: string;
    } | {
        readonly __typename: "Biography";
        readonly article: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "Institution";
        readonly institution: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        readonly __typename: "ActiveRegion";
        readonly region: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $fragmentRefs": FragmentRefs<"PersonAliases_person" | "PersonPatronymsAll_person" | "PersonCollectedAll_person" | "PersonInvolvedAll_person" | "PersonOrderedNames_person" | "PersonOrderedArticles_person">;
    readonly " $refType": "PersonBody_person";
};
export type PersonBody_person$data = PersonBody_person;
export type PersonBody_person$key = {
    readonly " $data"?: PersonBody_person$data;
    readonly " $fragmentRefs": FragmentRefs<"PersonBody_person">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ModelLink_model"
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonBody_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "givenNames",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initials",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tussenvoegsel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "death",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "namingConvention",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "target",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "type": "Wiki",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Article",
              "kind": "LinkedField",
              "name": "article",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "Biography",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Collection",
              "kind": "LinkedField",
              "name": "institution",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "Institution",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Region",
              "kind": "LinkedField",
              "name": "region",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "ActiveRegion",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonAliases_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonPatronymsAll_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonCollectedAll_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonInvolvedAll_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonOrderedNames_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonOrderedArticles_person"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();
(node as any).hash = 'cb7aa15b5b759153186dcbe3d0a9d610';
export default node;
